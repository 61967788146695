<template>
    <div id="main_container" class="visible">
        <div id="header_in">
            <div id="logo_in"><a href="https://skalaplus.com" target="_blank"><img src="https://skalaplus.com/images/logo/logo.png" alt="Skala Plus"></a></div>
        </div>
        <div class="wrapper_in">
            <div class="container-fluid">
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="tab_1">
                        <div class="subheader" id="quote"></div>
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'App',
        components: {

        }
    }
</script>
