import { createApp } from 'vue'
import App from './App.vue'
import router from "../router"
import httpPlugin from "../plugins/httpPlugin"
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App);

app.config.globalProperties.questionTypes = {
    QType_Information: "094e0922-886b-4628-b558-9d5298350720",
    QType_MultipleChoice: "d14ea5ba-7870-4475-a286-d87f8f5e80e2",
    QType_MultipleSelect: "baceb460-5b50-4193-bf64-ff6e5bf2c112",
    QType_Point: "9443e0e6-3f71-4b26-b250-ba6ea811e4cf",
    QType_Final: "0703f8fe-75e0-40d1-b580-3b4864135e5b",
    QType_DataInput: "fe60580c-23f4-4c06-9850-1068cf0de009"
};

app.config.globalProperties.dateFormats = {
    date: "dd.MM.yyyy",
    datetime: "dd.MM.yyyy HH:mm",
    time: "HH:mm",
};

app.config.globalProperties.statics = {
    otherText: "DİĞER-",
    Opet_KusursuzMusteriMemnuniyeti: "d762c4b0-1a35-4688-932f-9139e73372f9",
    yakitAldim: "Yakıt Aldım",
    wcKullandim: "Tuvaleti Kullandım",
    marketKullandim: "Marketi Kullandım",
    ratingClass: "rating-"

};


fetch(process.env.BASE_URL + "appsettings." + process.env.NODE_ENV + ".json")
    .then((response) => response.json())
    .then((config) => {
        // either use window.config
        window.config = config
        // or use [Vue Global Config][1]
        app.config.globalProperties.config = config
        // FINALLY, mount the app
        app.use(router);
        app.use(PerfectScrollbar, {
            watchOptions: true,
            options: {
                suppressScrollX: true
            }
        })
        app.use(httpPlugin)
            .mount('#app');
    })





Array.prototype.removeByValue = function (val) {
    for (var i = 0; i < this.length; i++) {
        if (this[i] === val) {
            this.splice(i, 1);
            i--;
        }
    }
    return this;
}
