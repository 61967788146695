<template>
    <div class="container ">
        <!--!-->
        <!--!--><!--!--><h3>Ulaşmaya çalıştığınız anketi bulamadık </h3><!--!-->
    </div>
</template>

<script>
    export default {
        name: '_404',

    }</script>